import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/bottle-landing.png'


const _hoisted_1 = { class: "page-error general-page" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "body" }

import { useI18next } from '@composables/i18next';
    import { useBasePage } from '@composables/page';
    import { useStore } from 'vuex';
    import { OutgoingAffiliate } from '@roc/affiliate';

    // Setup
    
export default {
  __name: 'ErrorPage',
  props: {
        error: {
            type: String,
            default: 'default',
        },
    },
  setup(__props) {

    const store = useStore();

    

    useBasePage('error');
    const { vT } = useI18next('error');

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives(_createElementVNode("h2", _hoisted_2, null, 512), [
      [_unref(vT), [`error:${__props.error}.headline_copy`, 'error:default.headline_copy']]
    ]),
    _withDirectives(_createElementVNode("p", _hoisted_3, null, 512), [
      [_unref(vT), { key: [`error:${__props.error}.body_copy`, 'error:default.body_copy'], tb2Url: _unref(store).state.app.tb2URL }]
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      class: "bottle-image",
      src: _imports_0,
      alt: "bottle-image"
    }, null, -1)),
    _createVNode(_unref(OutgoingAffiliate), {
      "affiliate-id": "continue",
      class: "button tertiary"
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("span", null, null, 512), [
          [_unref(vT), [`error:${__props.error}.button_copy`, 'error:default.button_copy']]
        ])
      ]),
      _: 1
    })
  ]))
}
}

}