import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "the-footer" }
const _hoisted_2 = { class: "footer-links" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href", "title"]
const _hoisted_6 = { class: "last_nav_item" }
const _hoisted_7 = { class: "disclaimer mt-xs mb-l" }
const _hoisted_8 = {
  key: 0,
  class: "artificial-tests"
}
const _hoisted_9 = ["textContent"]
const _hoisted_10 = ["src"]

import { useStore } from 'vuex';
    import { computed } from 'vue';
    import { useI18next } from '@composables/i18next';
    import { openPopup } from '../popup';


    // Setup
    
export default {
  __name: 'TheFooter',
  setup(__props) {

    const store = useStore();

    const { t, vT } = useI18next(['footer', 'links', 'global']);
    const app = computed(() => store.state.app);

    const rulesLink = computed(() => `<a href="${store.state.app.tb2URL}public/fulfillment/rules.pdf" title="${t('links:rules_copy')} ${t('links:pdf')}" target="_blank">${t('links:rules_copy')}</a>`);

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: `${app.value.tb2URL}public/fulfillment/rules.pdf`,
            target: "_blank"
          }, _toDisplayString(_unref(t)('links:rules_copy')), 9, _hoisted_3)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: _unref(t)('links:privacy_url'),
            target: "_blank"
          }, _toDisplayString(_unref(t)('links:privacy_copy')), 9, _hoisted_4)
        ]),
        _createElementVNode("li", null, [
          _withDirectives(_createElementVNode("a", {
            href: _ctx.$t('links:terms_url'),
            title: _ctx.$t('links:terms_title'),
            target: "_blank"
          }, null, 8, _hoisted_5), [
            [_unref(vT), 'links:terms_copy']
          ])
        ]),
        _createElementVNode("li", _hoisted_6, [
          _createElementVNode("a", {
            href: "/faq",
            onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => (_unref(openPopup) && _unref(openPopup)(...args)), ["prevent"]))
          }, _toDisplayString(_unref(t)('links:faq_copy')), 1)
        ])
      ])
    ]),
    _withDirectives(_createElementVNode("p", _hoisted_7, null, 512), [
      [_unref(vT), { key: 'footer:disclaimer', rulesLink: rulesLink.value, interpolation: { escapeValue: false } }]
    ]),
    (_unref(store).state.app.isAutomatedTest)
      ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
          _createElementVNode("span", {
            class: "test-copy",
            textContent: _toDisplayString(_unref(t)('testNamespace:testKey'))
          }, null, 8, _hoisted_9),
          _createElementVNode("img", {
            class: "language-flag",
            alt: "Language flag",
            src: _ctx.$i18nAsset('img/flag.svg'),
            style: {"display":"inline","width":"2em","padding":"0 0.5em","vertical-align":"middle"}
          }, null, 8, _hoisted_10)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

}