
import Cookies from 'js-cookie';

const getDefaultState = () => ({
    pageName: 'default',
    pageClasses: [],
    dialogName: null,
    dialogIsModal: false,
    rapidTemplate: false && "a",
    animationEnabled: undefined,
    soundEnabled: undefined,
});

const state = getDefaultState();

const getters = {
    // convenience getter for elements that need to know when modals are open/closed
    // such as adding dynamic attributes to help create modal locks (inert, aria-hidden, etc).
    // ex: :aria-hidden="String(this.$store.getters['ui/isModalOpen'])" vs
    // :aria-hidden="String(!!this.$store.state.ui.modalName)"
    isModalOpen: (state) => !!state.modalName,

    isAnimationEnabled (state, getters, rootState) {
        const animationCookieName = `${rootState.app.name}:animationEnabled`;
        const animationCookie = Cookies.get(animationCookieName);
        const { animationEnabled } = state;
        return animationCookie !== 'false' && (animationEnabled || animationEnabled === undefined);
    },

    isSoundEnabled (state, getters, rootState) {
        const soundCookieName = `${rootState.app.name}:soundEnabled`;
        const soundCookie = Cookies.get(soundCookieName);
        const { soundEnabled } = state;
        return soundCookie !== 'false' && (soundEnabled || soundEnabled === undefined);
    },
};

const { body } = document;
const LOADING_CLASS = 'page-loading';

const mutations = {
    pageEnter (state, newPage) {
        if (state.pageClasses?.length) {
            body.classList.remove(...state.pageClasses);
        }

        state.pageName = newPage.pageName || newPage.$options.name;
        state.pageClasses = newPage.pageClasses;

        body.id = state.pageName;

        if (body.classList.contains(LOADING_CLASS)) {
            body.classList.remove(LOADING_CLASS);
        }
        if (state.pageClasses && state.pageClasses.length) {
            body.classList.add(...state.pageClasses);
        }
    },
    pageLeave (state) {
        body.classList.add(LOADING_CLASS);
    },

    dialogOpen (state, { dialogName, isModal }) {
        state.dialogName = dialogName;
        state.isDialogModal = isModal;
    },

    dialogClose (state) {
        state.dialogName = null;
        state.dialogIsModal = false;
    },

    setAnimationEnabled (state, { animationEnabled, name }) {
        const animationCookieName = `${name}:animationEnabled`;
        Cookies.set(animationCookieName, animationEnabled);
        state.animationEnabled = animationEnabled;
    },

    setSoundEnabled (state, { soundEnabled, name }) {
        const soundCookieName = `${name}:soundEnabled`;
        Cookies.set(soundCookieName, soundEnabled);
        state.soundEnabled = soundEnabled;
    },
};

const actions = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
