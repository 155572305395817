import { unref as _unref, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/global-logo.png'


const _hoisted_1 = {
  id: "header",
  role: "banner",
  "data-e2e": "global-header"
}
const _hoisted_2 = ["alt"]

import { onMounted, ref, computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';

    import { useI18next } from '@composables/i18next';

    // Setup
    
export default {
  __name: 'TheHeader',
  setup(__props) {

    const router = useRouter();
    const { t } = useI18next('global');

    const store = useStore();
    const award = computed(() => store.state.award);

    const h1 = ref();

    // Lifecycle
    onMounted(() => {
        router.afterEach(() => h1.value.focus());
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("h1", {
      ref_key: "h1",
      ref: h1,
      tabindex: "-1"
    }, [
      _withDirectives(_createElementVNode("img", {
        src: _imports_0,
        alt: _unref(t)('global:title')
      }, null, 8, _hoisted_2), [
        [_vShow, !award.value.isPlaying]
      ])
    ], 512)
  ]))
}
}

}