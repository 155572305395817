import axios from 'axios';
import Vue from 'vue';
import api from '../../api';

// List challenge IDs that should be excluded from frontend challenge display and/or logic
const challengesExcludedFromFrontend = new Set([]);

const getDefaultState = () => ({
    loaded: false,
    challenges: {},
});

const state = getDefaultState();

const getters = {
    areAllChallengesCompleted (state, getters) {
        return getters.sortedChallengeIds.completed.length > 0 &&
            getters.sortedChallengeIds.claimable.length === 0 &&
            getters.sortedChallengeIds.inProgress.length === 0;
    },

    sortedChallengeIds () {
        const sortedChallenges = {
            completed: [],
            claimable: [],
            inProgress: [],
        };

        for (const challengeId in state) {
            if (!challengesExcludedFromFrontend.has(challengeId)) {
                const challenge = state[challengeId];

                if (challenge.results.completed) {
                    sortedChallenges.completed.push(challengeId);
                }
                else if (challenge.results.progress === challenge.properties.target) {
                    sortedChallenges.claimable.push(challengeId);
                }
                else {
                    sortedChallenges.inProgress.push(challengeId);
                }
            }
        }

        return sortedChallenges;
    },

    userChallengeIds (state) {
        return Object.keys(state.challenges);
    },

    userChallenges (state) {
        return state.challenges;
    },
};

const mutations = {
    resetChallenges (state) {
        state = getDefaultState();
    },

    updateChallenges (state, { achievements }) {
        for (const achievement of achievements) {
            Vue.set(state.challenges, achievement.achievementName, { ...achievement });
        }

        state.loaded = true;
    },

    progressChallenge (state, { challengeId }) {
        if (challengeId in state.challenges) {
            const { progress } = state.challenges[challengeId].results;
            const { target } = state.challenges[challengeId].properties;
            // use Math.min to prevent us from progressing a challenge past the target
            Vue.set(state.challenges[challengeId].results, 'progress', Math.min(progress + 1, target));
        }
    },

    completeChallenge (state, { challengeId }) {
        Vue.set(state.challenges[challengeId].results, 'completed', true);
    },
};

const actions = {
    async loadChallenges ({ commit }) {
        try {
            const response = await axios.get(`${api.base}/challenges/checkAll`);
            const achievements = response.data;

            commit('updateChallenges', { achievements });
        }
        catch (error) {
            console.error('could not load challenges', error);

            throw error;
        }
    },

    async claimChallenge ({ commit }, { challengeId }) {
        try {
            const response = await axios.post(`${api.base}/challenges/claim`, { challengeId });
            const { results } = response.data;

            commit('completeChallenge', { challengeId });

            if (results.properties.award) {
                commit('profile/incrementPlaysRemaining', {
                    amount: results.properties.award,
                }, { root: true });
            }

            return results;
        }
        catch (error) {
            console.error('could not claim challenge', error);

            throw error;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
