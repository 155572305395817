
// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    LandingPage = async () => import(
        /* webpackChunkName: 'lifecycle' */
        '../views/LandingPage.vue'
    );

export default [
    { path: '/landing', alias: '/', component: LandingPage, meta: {
        public: true,
        activeInPhase: ':any',
    } },
];
