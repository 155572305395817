
import store from '../store/index';

// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    GamePage = async () => import(
        /* webpackChunkName: 'game' */
        '../views/GamePage.vue'
    ),
    LimiterPage = async () => import(
        /* webpackChunkName: 'game' */
        '../views/LimiterPage.vue'
    );

export default [
    { path: '/', redirect: (to) => {
        // Used to send server-side errors to the '/error' page.
        if (store.state.app.defaultRoute) {
            return store.state.app.defaultRoute;
        }
        // Default routes for logged-in users.
        else if (
            store.getters['profile/loggedIn'] &&
            !store.getters['profile/isSessionExpired']
        ) {

            if (!store.state.profile.plays_remaining) {
                return '/limiter';
            }
            else {
                return '/game';
            }
        }

        else if (store.getters['profile/returning']) {
            return '/landing';
        }

        // Default route for a user we know nothing about.
        return '/landing';
    } },
    { path: '/game',  component: GamePage },
    { path: '/limiter',  component: LimiterPage },
];
